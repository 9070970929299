'use client'

import Image from 'next/image'

import { ProductName } from '@/components/product-name'
import { GiftVariants } from '@/components/variants'
import { GiftCardProductListItemData } from '@/common/types/product-types'
import { GiftCardProductAmountFragment } from '@/api'

export type Props = {
  productData: GiftCardProductListItemData
  onClose?: () => void
}

export function ObsoletePurchaseDialogGiftCardVariants({
  productData,
  onClose,
}: Props): JSX.Element {
  return (
    <div className="mb-4 text-left">
      <Image
        src={productData?.image?.url?.full ?? ''}
        alt={productData?.name ?? 'alternative'}
        priority
        width={500}
        height={500}
        className="object-contain w-full h-[215px]"
      />

      <ProductName size="lg" dialogVariant>
        {productData?.name}
      </ProductName>

      <GiftVariants
        productName={productData?.name ?? ''}
        sku={productData?.sku ?? ''}
        giftCardAmounts={
          (productData?.giftcard_amounts as GiftCardProductAmountFragment[]) ??
          []
        }
        isPopupVariant
        onClose={onClose}
      />
    </div>
  )
}

'use client'

import { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'

import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { useUserTracking } from '@/common/hooks/user-tracking-hook'
import {
  GtmScheduler,
  GtmTrackerState,
} from '@/common/services/tracking/google/gtm-scheduler'
import {
  createHomepageGtmScheduler,
  trackHomepage,
} from './homepage-tracking-utils'

export function useHomepageTracking() {
  const { trackUser, loaded } = useUserTracking()
  const gtmScheduler = useRef<GtmScheduler>()
  const pathname = usePathname()

  if (!gtmScheduler.current) {
    gtmScheduler.current = createHomepageGtmScheduler()
  }

  useEffect(() => {
    if (gtmScheduler.current) {
      trackHomepage(gtmTracker, gtmScheduler.current, loaded, trackUser)
    }
  }, [trackUser, loaded])

  useEffect(() => {
    if (pathname) {
      gtmScheduler.current?.scheduleInitialEvent(
        GtmTrackerState.VirtualPageview,
        () => gtmTracker.trackVirtualPageView(pathname),
      )
    }
  }, [pathname])
}

'use client'

import Image from 'next/image'

import { ProductName } from '@/components/product-name'
import { ConfigurableVariants } from '@/components/variants'
import { ConfigurableProductListItemData } from '@/common/types/product-types'
import { ObsoleteProductDetailStorage } from '@/modules/product/components/detail/components/storage'
import { selectors } from '@/common/constants/selectors-constants'
import { AddToCartEventHandlerParams } from '@/providers/cart/utils/add-to-cart.utils'

export type Props = {
  configurableProductData: ConfigurableProductListItemData
  isLongTermUnavailable: boolean
  isInStock: boolean
  onClose?: () => void
  onAddToCart: (params: AddToCartEventHandlerParams) => Promise<void>
}

export function ObsoletePurchaseDialogConfigurableVariants({
  configurableProductData,
  isLongTermUnavailable,
  isInStock,
  onClose,
  onAddToCart,
}: Props): JSX.Element {
  return (
    <>
      <div className="mb-4 text-left">
        <Image
          src={configurableProductData?.image?.url?.full ?? ''}
          alt={configurableProductData?.name ?? 'alternative'}
          priority
          width={500}
          height={500}
          className="object-contain w-full h-[215px]"
          data-test="variant-image"
        />

        <ProductName
          size="lg"
          dialogVariant
          data-test={selectors.HP.productName}
        >
          {configurableProductData?.name}
        </ProductName>

        <div className="-mt-6 mb-1">
          <ObsoleteProductDetailStorage
            isConfigurableProduct
            longTermUnavailable={isLongTermUnavailable}
            isGiftCardProduct={false}
            inStock={isInStock}
            small
          />
        </div>

        <ConfigurableVariants
          configurableProductData={configurableProductData}
          isPopupVariant
          onClose={() => onClose?.()}
          onAddToCartEvent={onAddToCart}
        />
      </div>
    </>
  )
}

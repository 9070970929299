'use client'

import { GtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { TrackingPage } from '@/common/services/tracking/google/gtm-types'
import {
  GtmScheduler,
  GtmTrackerState,
} from '@/common/services/tracking/google/gtm-scheduler'

export function createHomepageGtmScheduler(): GtmScheduler {
  return new GtmScheduler(createHomepageGtmEventsOrder())
}

export function createHomepageGtmEventsOrder(): GtmTrackerState[] {
  return [
    GtmTrackerState.Started,
    GtmTrackerState.PageView,
    GtmTrackerState.User,
    GtmTrackerState.VirtualPageview,
  ]
}

export function trackHomepage(
  gtmTracker: GtmTracker,
  gtmScheduler: GtmScheduler,
  loaded: boolean,
  trackUser: () => void,
) {
  gtmScheduler.scheduleInitialEvent(
    GtmTrackerState.Started,
    gtmTracker.trackGtmStart,
  )
  gtmScheduler.scheduleInitialEvent(GtmTrackerState.PageView, () =>
    gtmTracker.trackPage(TrackingPage.HomePage, TrackingPage.HomePage),
  )

  if (loaded) {
    gtmScheduler.scheduleInitialEvent(GtmTrackerState.User, trackUser)
  }
}
